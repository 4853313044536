export default {
	setUser(state, payload) {
		state.userId = payload.userId;
		state.isCurrentUser = payload.isCurrentUser;
		state.didAutoLogout = false;
	},
	setAutoLogout(state) {
		state.didAutoLogout = true;
	},
	writeBadResponse(state, payload){
		state.badResponse = payload;
	}
}
