export default {
	userId(state) {
		return state.userId;
	},
	isAuthentificated(state) {
		return !!state.userId;
	},
	didAutoLogout(state) {
		return state.didAutoLogout;
	},
	getBadResponse(state) {
		return state.badResponse;
	}
}
