<template>
	<div class="spinner-border" role="status">
		<span class="visually-hidden">Loading...</span>
	</div>
</template>

<script>
export default {};
</script>

<style>
</style>
