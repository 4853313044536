import { createApp } from 'vue'

import router from './router.js'
import store from './store/index.js'

import App from './App.vue'
import BaseTitle from './components/ui/BaseTitle.vue'
import BaseButton from './components/ui/BaseButton.vue'
import BaseDialog from './components/ui/BaseDialog.vue'
import BaseSpinner from './components/ui/BaseSpinner.vue'

import './registerServiceWorker'

const app = createApp(App);

app.use(router);
app.use(store);

app.component('base-title', BaseTitle);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-spinner', BaseSpinner);


app.mount('#app');
