<template>
		<tr>
			<th scope="row">{{ table.currencyCode }}</th>
			<td>{{ table.time }}</td>
			<td>{{ table.mcc }}</td>
			<td class="text-nowrap text-start">
				<i v-if="table.operationAmount < 0" class="fas fa-arrow-down text-danger fa-lg"></i>
				<i v-else class="fas fa-arrow-up text-success fa-lg"></i>
				&#160;
				{{ operationAmount }}
			</td>
			<td>{{ table.commissionRate }}</td>
			<td>{{ table.balance }}</td>
			<td>{{ table.cashbackAmount }}</td>
		</tr>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		currencyCode: {
			type: String,
			required: true,
		},
		mcc: {
			type: Number,
			required: true,
		},
		operationAmount: {
			type: Number,
			required: true,
		},
		commissionRate: {
			type: Number,
			default: 0,
		},
		balance: {
			type: Number,
			required: true,
		},
		cashbackAmount: {
			type: Number,
			default: 0,
		},
		time: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			table: {
				id: this.id,
				amount: this.amount,
				balance: this.balance,
				cashbackAmount: this.cashbackAmount,
				commissionRate: this.commissionRate,
				currencyCode: this.currencyCode,
				hold: this.hold,
				mcc: this.mcc,
				operationAmount: this.operationAmount,
				receiptId: this.receiptId,
				time: this.time,
			}
		}
	},
	created() {
		// console.log(this.table);
	},
};
</script>

<style lang="scss">
	.table-hover > tbody > tr:hover {
		--bs-table-hover-bg: #a5f1e8;
		background-color: #a6d2e8;
	}
</style>
