import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from './pages/dashboard/Dashboard.vue';
import Import from './pages/import/Import.vue';
import UserAuth from './pages/auth/UserAuth.vue';
import NotFound from './pages/NotFound.vue';

import store from './store/index.js'

const routes = [
	{ path: '/', redirect: '/dashboard' },
	{ path: '/dashboard', component: Dashboard, meta: {requiresAuth: true} },
	{ path: '/import', component: Import, meta: {requiresAuth: true} },
	{ path: '/auth', component: UserAuth, meta: { requiresUnauth: true } },
	{ path: '/:notFound(.*)', component: NotFound}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(function (to, _, next) {
	// console.log(store.getters.isAuthentificated);
    if (to.meta.requiresAuth && !store.getters.isAuthentificated) {
        next('/auth');
    } else if (to.meta.requiresUnauth && store.getters.isAuthentificated) {
        next('/');
    } else {
		next();
	}
})

export default router
