export default {
	currentData(state) {
		return state.data;
	},
	getDatabaseErrorState(state) {
		return state.errorDatabase;
	},
	getDatabaseFilling(state) {
		return state.emptyDatabae;
	},
}
