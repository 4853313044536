<template>
	<div class="wrapper">
		<the-header></the-header>
		<main class="main">
			<router-view></router-view>
			<span
				class="overlay js-overlay"
				:class="overlayIsHidden ? 'is-active' : ''"
				@hamburger-is-open="toggleOverlay"
			></span>
		</main>
		<the-footer></the-footer>
	</div>
</template>

<script>
// import firebaseApp from "./firebaseInit";
// import firebase from "firebase/firebase";
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";

export default {
	components: {
		TheHeader,
		TheFooter,
	},
	data() {
		return {
			overlayIsHidden: false,
		};
	},
	provide() {
		return {
			overlayState: this.toggleOverlay,
		};
	},
	computed: {
		didAutoLogout() {
			return this.$store.getters.didAutoLogout;
		},
	},
	methods: {
		toggleOverlay(value) {
			this.overlayIsHidden = value;
		},
	},
	created() {
		this.$store.dispatch("tryLogin");
	},
	watch: {
		didAutoLogout(curVal, oldVal) {
			console.log(curVal, oldVal);
			if (curVal && curVal !== oldVal) {
				this.$router.replace("/auth");
			}
		},
	},
};
</script>

<style lang="scss">
@import '~mdb-vue-ui-kit/src/scss/index.free';
:root {
	--primary-color: var(--bs-primary, #87afd7);
	--secondary-color: var(--bs-secondary, #a0cfe4);
}

* {
	box-sizing: border-box;
}

html,
body,
#app {
	height: 100%;
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-top: 60px;
}

main {
	flex: 1 0 auto;
}

a {
	color: inherit;
	text-decoration: none;
}

.hidden {
	display: none;
}

.main {
	background-color: $grey-light;
	// height: 100vh;

	&__title {
		text-align: center;
	}
}

thead {
	th {
		cursor: pointer;

		&:not(:first-child) {
			text-align: center;
		}
	}
}

td {
	text-align: center;
}

.overlay {
	@include animate(opacity);

	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	background-color: $black;
	opacity: 0;
	pointer-events: none;

	&.is-active {
		pointer-events: all;
		opacity: 0.75;
	}
}

.rainbow {
	@extend %rainbow;
}
</style>
