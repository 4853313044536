<template>
	<MDBBtn
		v-if="!link"
		color="primary"
		size="lg"
		class="btn"
		:class="!!mode ? 'btn--' + mode : ''"
	>
		<slot> </slot>
	</MDBBtn>
	<router-link
		v-else
		:to="to"
		class="btn"
		:class="!!mode ? 'btn--' + mode : ''"
	>
		<slot> </slot>
	</router-link>
</template>

<script>
import { MDBBtn } from "mdb-vue-ui-kit";

export default {
	components: {
		MDBBtn,
	},
	props: {
		mode: {
			type: String,
			required: false,
			default: null,
		},
		link: {
			type: Boolean,
			required: false,
			default: false,
		},
		to: {
			type: String,
			required: false,
			default: "/",
		},
	},
};
</script>

<style scoped lang="scss">
</style>
