export default {
	fillLocalData(state, payload) {
		state.data = payload;
	},

	databaseHasError(state, payload) {
		state.errorDatabase = payload;
	},

	databaseIsEmpty(state, payload) {
		state.errorDatabase = payload;
	},
}
