<template>
	<base-title>Dashboard</base-title>
	<div class="table-responsive">
		<table
			class="table table-striped table-hover table-sm table-light border-secondary"
		>
			<thead>
				<tr>
					<th
						scope="col"
						@click="
							sortData('currencyCode', currencyCodeOrder);
							currencyCodeOrder = !currencyCodeOrder;
						"
					>
						Назва
						<i
							class="fas mx-3"
							:class="
								!!currencyCodeOrder
									? ' fa-caret-down'
									: ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('time', timeOrder);
							timeOrder = !timeOrder;
						"
					>
						Дата, час
						<i
							class="fas mx-3"
							:class="
								!!timeOrder ? ' fa-caret-down' : ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('mcc', mccOrder);
							mccOrder = !mccOrder;
						"
					>
						Код
						<i
							class="fas mx-3"
							:class="
								!!mccOrder ? ' fa-caret-down' : ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('operationAmount', operationAmountOrder);
							operationAmountOrder = !operationAmountOrder;
						"
					>
						Сума
						<i
							class="fas mx-3"
							:class="
								!!operationAmountOrder
									? ' fa-caret-down'
									: ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('commissionRate', commissionRateOrder);
							commissionRateOrder = !commissionRateOrder;
						"
					>
						Коміссія
						<i
							class="fas mx-3"
							:class="
								!!commissionRateOrder
									? ' fa-caret-down'
									: ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('balance', balanceOrder);
							balanceOrder = !balanceOrder;
						"
					>
						Залишок
						<i
							class="fas mx-3"
							:class="
								!!balanceOrder
									? ' fa-caret-down'
									: ' fa-caret-up'
							"
						></i>
					</th>
					<th
						scope="col"
						@click="
							sortData('cashbackAmount', cashbackAmountOrder);
							cashbackAmountOrder = !cashbackAmountOrder;
						"
					>
						Кешбек
						<i
							class="fas mx-3"
							:class="
								!!cashbackAmountOrder
									? ' fa-caret-down'
									: ' fa-caret-up'
							"
						></i>
					</th>
				</tr>
			</thead>
			<tbody v-if="!isLoading && !dataIsEmpty">
				<the-item
					v-for="row in currentData"
					:key="row.id"
					v-bind="row"
				></the-item>
			</tbody>
			<tbody v-if="isLoading && !dataIsEmpty">
				<tr>
					<td colspan="7">
						<base-spinner></base-spinner>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div
		v-if="dataIsEmpty || dataHasError"
		class="t-message py-3 px-4 text-center rounded mx-auto mt-3 text-dark fw-light fs-6"
	>
		<p class="mb-0">Ooops, there is some issue...</p>
		<p class="mb-0">The database is empty or you do not have permission</p>
	</div>
</template>

<script>
import TheItem from "./TheItem.vue";

export default {
	components: {
		TheItem,
	},
	data() {
		return {
			isLoading: false,
			currentData: null, // TODO: cleare when user logout
			sortedData: null,
			currencyCodeOrder: true,
			timeOrder: true,
			mccOrder: true,
			operationAmountOrder: true,
			commissionRateOrder: true,
			balanceOrder: true,
			cashbackAmountOrder: true,
			dataIsEmpty: false,
			dataHasError: false,
		};
	},
	created() {
		return this.loadData();
	},
	methods: {
		async loadData() {
			this.isLoading = true;

			// this.$store.dispatch("test");

			try {
				await this.$store.dispatch("downloadData");
			} catch (error) {
				this.error = error.message || "Something went wrong";
			}

			this.dataHasError = await this.$store.getters[
				"getDatabaseErrorState"
			];
			this.dataIsEmpty = await this.$store.getters["getDatabaseFilling"];

			if (!this.dataHasError && !this.dataIsEmpty) {
				this.currentData = await this.$store.getters["currentData"];
				this.sortData("time", this.timeOrder);
				this.timeOrder = !this.timeOrder;
			}

			this.isLoading = false;
		},
		sortData(value, sortOrder) {
			function compareValues(key, order = "asc") {
				return function innerSort(a, b) {
					if (
						!Object.prototype.hasOwnProperty.call(a, key) ||
						!Object.prototype.hasOwnProperty.call(b, key)
					) {
						// property doesn't exist on either object
						return 0;
					}

					const varA =
						typeof a[key] === "string"
							? a[key].toUpperCase()
							: a[key];
					const varB =
						typeof b[key] === "string"
							? b[key].toUpperCase()
							: b[key];

					let comparison = 0;
					if (varA > varB) {
						comparison = 1;
					} else if (varA < varB) {
						comparison = -1;
					}
					return order === "desc" ? comparison * -1 : comparison;
				};
			}
			this.sortedData = this.currentData.sort(
				compareValues(value, sortOrder ? "asc" : "desc")
			);
		},
	},
};
</script>

<style lang="scss" scoped>
th {
	vertical-align: top;
}
i {
	display: block;
	margin-top: 5px;
}
.t-message {
	background-color: rgba(29, 233, 182, 0.15);
	border-left: 4px solid rgba(98, 0, 234, 0.55);
	max-width: 650px;
	width: 90%;
}
</style>
