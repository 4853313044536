<template>
	<MDBContainer class="h-100">
		<MDBRow class="h-100">
			<section class="w-100">
				<base-title>Here you can upload your new data</base-title>
				<div
					v-if="isLoading"
					class="
						d-flex
						align-items-center
						justify-content-center
						h-25
					"
				>
					<base-spinner></base-spinner>
				</div>
				<div v-else class="w-100 d-flex justify-content-center py-5">
					<form
						@submit.prevent="getDataFromMono"
						class="
							import-form
							d-flex
							justify-content-center
							flex-wrap
							w-50
						"
					>
						<transition>
							<div
								v-if="currentStep === 1"
								class="
									w-100
									d-flex
									justify-content-center
									flex-wrap
									step-1-wr
								"
							>
								<h3
									class="
										mb-3
										text-start
										w-100
										text-dark
										header
									"
									data-header="first"
									:class="{ 'text-muted': currentStep === 2 }"
									@click.prevent="getClientInfoFromMono"
								>
									1. Refresh data
								</h3>
								<div class="mb-3 w-100">
									<MDBInput
										label="Type your token"
										type="text"
										id="inputFile"
										required="required"
										autocomplete="off"
										v-model.trim="token"
									/>
								</div>
								<div class="mb-3 me-auto ms-auto">
									<MDBPopover v-model="popover_1">
										<template #reference>
											<span
												class="popup-msg d-block"
												color="danger"
												size="lg"
												@click="popover_1 = !popover_1"
											>
												<u>
													Get my token FROM Monobank
													API
												</u>
											</span>
										</template>
										<template #body>
											<a
												href="https://api.monobank.ua/"
												target="_blank"
												rel="noopener noreferrer"
											>
												https://api.monobank.ua/
											</a>
										</template>
									</MDBPopover>
								</div>
								<div class="mb-3">
									<MDBBtn
										class="
											refresh-border refresh-color
											border border-2
											mx-0
										"
										:class="{ disabled: !token }"
										size="lg"
										@click.prevent="getClientInfoFromMono"
									>
										Refresh data
									</MDBBtn>
								</div>
							</div>
						</transition>
						<transition>
							<div
								v-if="currentStep === 2"
								class="
									w-100
									d-flex
									justify-content-center
									flex-wrap
								"
							>
								<h3
									class="
										mb-3
										text-start
										w-100
										text-dark
										header
									"
									data-header="second"
									:class="{ 'text-muted': currentStep === 1 }"
									@click.prevent="getClientInfoFromMono"
								>
									2. Upload data
								</h3>
								<div class="mb-3 w-100 mx-2 text-capitalize">
									<MDBRadio
										v-for="(
											account, index
										) in accountsLists"
										:key="index"
										:label="account.type"
										:value="account.id"
										v-model="accountId"
										inline
									></MDBRadio>
								</div>
								<div class="mb-3 flex-grow-1 mx-2">
									<MDBInput
										label="From:"
										type="date"
										id="start"
										value="2021-01-01"
										name="trip-start"
										min="2000-01-01"
										required="required"
										v-model="start"
									/>
								</div>
								<div class="mb-3 flex-grow-1 mx-2">
									<MDBInput
										label="To:"
										type="date"
										id="end"
										name="trip-end"
										min="2000-01-01"
										value="2021-01-01"
										required="required"
										v-model="end"
									/>
								</div>
								<div class="mb-3">
									<MDBBtn
										class="login-gradient login-color"
										size="lg"
										type="submit"
									>
										Grab data
									</MDBBtn>
								</div>
							</div>
						</transition>
					</form>
				</div>
			</section>
		</MDBRow>
	</MDBContainer>
</template>

<script>
import {
	MDBInput,
	MDBBtn,
	MDBContainer,
	MDBRow,
	MDBPopover,
	MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

export default {
	components: {
		MDBInput,
		MDBBtn,
		MDBRow,
		MDBContainer,
		MDBPopover,
		MDBRadio,
	},
	data() {
		return {
			formData: {},
			accountId: ref(null),
			isLoading: false,
			start: ref(null),
			end: ref(null),
			token: ref(""),
			popover_1: ref(false),
			popover_2: ref(false),
			currentStep: 1,
		};
	},
	computed: {
		accountsLists() {
			return this.$store.getters["getAccountsList"];
		},
	},
	created() {
		console.log(this.$store);
	},
	methods: {
		async getClientInfoFromMono() {
			if (this.currentStep === 1 && !!this.token) {
				await this.$store.dispatch("getClientDataFromMono", this.token);
			} else {
				console.log("There is some issue....");
				return false;
			}

			this.currentStep = 2;
		},

		async getDataFromMono() {
			this.isLoading = true;
			this.formData.accountId = this.accountId;
			this.formData.dateFrom = new Date(this.start).getTime().toString();
			this.formData.dateTo = new Date(this.end).getTime().toString();
			this.formData.token = this.token;

			await this.$store.dispatch("getDataMono", this.formData);

			this.$router.replace("/");
		},
	},
};
</script>

<style lang="scss">
.form-control[type="date"] {
	color: transparent;

	&.active,
	&.focus {
		color: inherit;
	}

}

.popup-msg {
	cursor: pointer;
	text-align: center;
}

.header {
	cursor: pointer;
}

.v-enter-from {
	opacity: 0;
	height: 0;
}

.v-enter-active,
.v-leave-active {
	transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.v-enter-to {
	opacity: 1;
	height: var(--step1height);
	transition-delay: 0.3s;
}

.v-leave-from {
	opacity: 1;
	height: var(--step1height);
}

.v-leave-to {
	opacity: 0;
	height: 0;
}
</style>
