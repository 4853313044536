<template>
	<!-- Footer -->
	<MDBFooter :text="['center', 'lg-start']" class="rainbow">
		<!-- Copyright -->
		<div
			class="text-center p-3"
			style="background-color: rgba(0, 0, 0, 0.2)"
		>
			© 2020 HFP
		</div>
		<!-- Copyright -->
	</MDBFooter>
	<!-- Footer -->
</template>

<script>
import { MDBFooter } from "mdb-vue-ui-kit";

export default {
	components: {
		MDBFooter,
	},
};
</script>

<style lang="scss">
</style>

