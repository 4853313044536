import firebaseApp from "../../../firebaseInit";

export default {
	async login(context, payload) {
		return context.dispatch('auth', {
			...payload,
			mode: 'login'
		})
	},
	async signup(context, payload) {
		return context.dispatch('auth', {
			...payload,
			mode: 'signup'
		})
	},
	async auth(context, payload) {
		const mode = payload.mode;
		let responseData = {};

		if (mode === 'signup') {
			responseData = await firebaseApp
				.auth()
				.createUserWithEmailAndPassword(payload.email, payload.password)
				.then((userCredential) => {
					// Signed in
					var user = userCredential.user;

					return user;
				})
				.catch((error) => {
					context.dispatch('logBadResponse', error);
				});
		} else {
			responseData = await firebaseApp
				.auth().signInWithEmailAndPassword(payload.email, payload.password)
				.then((userCredential) => {
					// Signed in
					var user = userCredential.user;

					return user;
				})
				.catch((error) => {
					context.dispatch('logBadResponse', error);
				});

		}

		context.commit('setUser', {
			userId: responseData.uid,
			isCurrentUser: responseData,
			didAutoLogout: false
		});

		localStorage.setItem('user', responseData.uid);

		firebaseApp.auth().onAuthStateChanged(function (user) {
			if (user) {
				console.log('onAuthStateChanged --- // User is signed in.');
			} else {
				console.log('onAuthStateChanged ---- // No user is signed in.');
				// No user is signed in.
				context.dispatch('autoLogout');
			}
		});
	},
	logBadResponse(context, payload) {
		context.commit('writeBadResponse', {
			badResponse: payload,
		});
	},
	tryLogin(context) {
		const user = localStorage.getItem('user');

		if (user) {
			context.commit('setUser', {
				userId: user,
			});
		} else {
			context.dispatch('logout');
		}
	},
	async logout(context) {
		await firebaseApp.auth().signOut().then(() => {
			// Sign-out successful.

			context.commit('setUser', {
				isCurrentUser: null,
				userId: null,
			});

			localStorage.removeItem('user');

			console.log('logout --- Sign-out successful');
		}).catch((error) => {
			// An error happened.
			console.log(error);
		});
	},
	autoLogout(context) {
		context.dispatch('logout');
		context.commit('setAutoLogout');
	}
}
