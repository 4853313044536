<template>
	<MDBContainer xxl class="h-100">
		<MDBRow class="h-100 align-items-center justify-content-center">
			<MDBCol sm="3" v-if="isLoading" class="text-center">
				<base-spinner></base-spinner>
			</MDBCol>
			<MDBCol v-else sm="8" md="6" lg="5" xl="4">
				<MDBRow
					@submit.prevent="submitForm"
					class="g-3 needs-validation"
					tag="form"
					novalidate
				>
					<MDBCol col="12">
						<MDBInput
							label="Email"
							size="lg"
							v-model.trim="email"
							invalidFeedback="Please type a correct and valid email"
							validFeedback="Looks good!"
							required="required"
							minLength="6"
							pattern="^.+@.+\..+$"
						/>
					</MDBCol>
					<MDBCol col="12">
						<MDBInput
							label="Password"
							size="lg"
							type="password"
							name="password"
							id="password"
							required="required"
							minLength="6"
							invalidFeedback="Password be at least 6 characters long"
							validFeedback="Looks good!"
							v-model.trim="password"
						/>
					</MDBCol>
					<MDBCol col="12">
						<MDBRow>
							<MDBCol>
								<MDBBtn
									class="login mb-2 w-100 login-gradient login-color"
									size="lg"
									type="submit"
									>{{ submitButtonCaption }}</MDBBtn
								>
							</MDBCol>
							<MDBCol>
								<MDBBtn
									class="signup w-100 signup-border signup-color"
									outline="primary"
									size="lg"
									@click="switchAuthMode"
									>{{ switchModeButtonCaption }}</MDBBtn
								>
							</MDBCol>
						</MDBRow>
					</MDBCol>
				</MDBRow>
			</MDBCol>
		</MDBRow>
	</MDBContainer>
	<MDBModal
		id="errorMeasage"
		tabindex="-1"
		labelledby="errorMeasageLabel"
		v-model="error"
		centered
	>
		<MDBModalHeader>
			<MDBModalTitle id="errorMeasageLabel">
				Oops, we have some trouble...
			</MDBModalTitle>
		</MDBModalHeader>
		<MDBModalBody>
			<p class="">{{ errorModalBody }}</p>
			<code>{{ errorModalCode }}</code>
		</MDBModalBody>
		<MDBModalFooter>
			<MDBBtn color="secondary" @click="handleError">Close</MDBBtn>
		</MDBModalFooter>
	</MDBModal>
</template>

<script>
import {
	MDBInput,
	MDBBtn,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBModalHeader,
	MDBModalTitle,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

export default {
	components: {
		MDBInput,
		MDBBtn,
		MDBRow,
		MDBContainer,
		MDBCol,
		MDBModalHeader,
		MDBModalTitle,
		MDBModal,
		MDBModalBody,
		MDBModalFooter,
	},
	data() {
		return {
			email: ref(""),
			password: ref(""),
			mode: "login",
			isLoading: false,
			error: false,
			errorMsg: {},
			errorModalBody: "",
			errorModalCode: "",
		};
	},
	computed: {
		submitButtonCaption() {
			if (this.mode === "login") {
				return "Login";
			} else {
				return "Signup";
			}
		},
		switchModeButtonCaption() {
			if (this.mode === "login") {
				return "Signup instead";
			} else {
				return "Login instead";
			}
		},
	},
	methods: {
		async submitForm(e) {
			e.target.classList.add("was-validated");
			this.isLoading = true;

			const actionPayload = {
				email: this.email,
				password: this.password,
			};

			try {
				if (this.mode === "login") {
					await this.$store.dispatch("login", actionPayload);
				} else {
					await this.$store.dispatch("signup", actionPayload);
				}

				const redirectUrl = `/${
					this.$route.query.redirect || "dashboard"
				}`;
				this.$router.replace(redirectUrl);
			} catch (err) {
				this.errorMsg = this.badResponse();
				this.error = true;
			}

			this.isLoading = false;
		},
		switchAuthMode() {
			if (this.mode === "login") {
				this.mode = "signup";
			} else {
				this.mode = "login";
			}
		},
		handleError() {
			this.error = false;
			this.errorMsg = {};
		},
		badResponse() {
			this.errorMsg = this.$store.getters.getBadResponse;
			this.errorModalBody = this.errorMsg.badResponse.message;
			this.errorModalCode = this.errorMsg.badResponse.code;
		},
	},
};
</script>

<style lang="scss">
.login {
}

.signup {
}
</style>
