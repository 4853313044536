import firebaseApp from "../../../firebaseInit";

export default {
	async getDataMono(context, payload) {
		const response = await fetch(
			`https://api.monobank.ua/personal/statement/${payload.accountId}/${payload.dateFrom}/${payload.dateTo}`,
			{
				method: "GET",
				headers: {
					"X-Token": payload.token,
				},
			}
		)
			.then((res) => {
				console.log("Data has gotten");
				return res.json();
			})
			.catch((error) => {
				console.error("Error reading data from Mono API ", error);
			});
			console.log(response);

		const transactionsObject = {};

		for (let i = 0; i < response.length; i++) {
			let key = response[i].id
			transactionsObject[`${key}`] = response[i];
		}

		await context.dispatch('uploadDataToFirebase', transactionsObject);
	},


	async getClientDataFromMono(context, payload) {
		const response = await fetch(
			`https://api.monobank.ua/personal/client-info`,
			{
				method: "GET",
				headers: {
					"X-Token": payload,
				},
			}
		)
			.then((res) => {
				console.log("Card IDs was written");

				return res.json();
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});

		let accountsList = []

		for (let i = 0; i < response.accounts.length; i++) {
			if (response.accounts[i].currencyCode === 980) { /* if currency is UAH */
				accountsList.push({
					type: response.accounts[i].type !== 'eAid' ? response.accounts[i].type : 'eSupport',
					id: response.accounts[i].id,
				});
			}
		}

		context.commit('setAccountsList', accountsList);
	},

	async uploadDataToFirebase(context, payload) {
		const userId = context.rootGetters.userId;

		await firebaseApp.firestore()
			.collection("users")
			.doc(userId)
			.set(
				payload,
				{ merge: true }
			)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
	},
}
