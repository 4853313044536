import { createStore } from 'vuex'

import authModule from './modules/auth/index.js';
import dashModule from './modules/dashboard/index.js';
import getMonoModule from './modules/import/index.js';

const store = createStore({
	modules: {
        auth: authModule,
		dashboard: dashModule,
		import: getMonoModule,
	},
})

export default store;
