<template>
	<section>
		<base-card>
			<h2>Page not found</h2>
			<p>This page could not be found.</p>
		</base-card>
	</section>
</template>

<script>
export default {};
</script>

<style>
</style>
