<template>
	<teleport to="body">
		<div v-if="show" class="backdrop"></div>
		<transition name="dialog">
			<dialog open v-if="show">
				<header>
					<slot name="header">
						<h2>{{ title }}</h2>
					</slot>
				</header>
				<section>
					<slot></slot>
				</section>
				<footer v-if="fixed">
					<slot name="actions">
						<base-button>Close</base-button>
					</slot>
				</footer>
			</dialog>
		</transition>
	</teleport>
	<!-- Button trigger modal -->
	<button
		type="button"
		class="btn btn-primary"
		data-mdb-toggle="modal"
		data-mdb-target="#exampleModal"
	>
		Launch demo modal
	</button>

	<!-- Modal -->
	<div
		class="modal top fade"
		id="exampleModal"
		tabindex="-1"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
		data-mdb-backdrop="true"
		data-mdb-keyboard="true"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						Modal title
					</h5>
					<button
						type="button"
						class="btn-close"
						data-mdb-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">...</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-mdb-dismiss="modal"
					>
						Close
					</button>
					<button type="button" class="btn btn-primary">
						Save changes
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		title: {
			type: String,
			required: false,
		},
		fixed: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ["close"],
	methods: {
		tryClose() {
			if (this.fixed) {
				return;
			}
			this.$emit("close");
		},
	},
};
</script>

<style scoped lang="scss">
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10;
}

dialog {
	position: fixed;
	top: 20vh;
	left: 10%;
	width: 80%;
	z-index: 100;
	border: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	padding: 0;
	margin: 0;
	overflow: hidden;
	background-color: white;
}

header {
	background-color: #3a0061;
	color: white;
	width: 100%;
	padding: 1rem;
}

header h2 {
	margin: 0;
}

section {
	padding: 1rem;
}

footer {
	padding: 1rem;
	display: flex;
	justify-content: flex-end;
	margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
	opacity: 0;
	transform: scale(0.8);
}

.dialog-enter-active {
	transition: all 0.3s ease-out;
}

.dialog-leave-active {
	transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
	opacity: 1;
	transform: scale(1);
}

@media (min-width: 768px) {
	dialog {
		left: calc(50% - 20rem);
		width: 40rem;
	}
}
</style>
