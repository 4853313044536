<template>
	<div class="p-5 text-center bg-light">
		<h1 class="mb-3">
			<slot></slot>
		</h1>
	</div>
</template>

<script>
export default {};
</script>

<style>
</style>
