<template>
	<header class="bg-light">
		<MDBNavbar expand="lg" light container class="fixed-top rainbow text-center">
			<MDBNavbarBrand href="/">HFP</MDBNavbarBrand>
			<MDBNavbarToggler
				@click="menuIsOpen = !menuIsOpen"
				target="#navbarSupportedContent"
			></MDBNavbarToggler>
			<MDBCollapse v-model="menuIsOpen" id="navbarSupportedContent">
				<MDBNavbarNav class="mb-2 mb-lg-0">
					<MDBNavbarItem to="/dashboard"> Dashboard </MDBNavbarItem>
					<MDBNavbarItem to="/import"> Import </MDBNavbarItem>
					<MDBNavbarItem to="/auth" v-if="!isLoggedIn">
						Log in
					</MDBNavbarItem>
					<MDBNavbarItem to="#" v-else @click.prevent="logout">
						Log out
					</MDBNavbarItem>
				</MDBNavbarNav>
			</MDBCollapse>
		</MDBNavbar>
	</header>
</template>

<script>
import {
	MDBNavbarToggler,
	MDBNavbarNav,
	MDBNavbarItem,
	MDBNavbar,
	MDBNavbarBrand,
	MDBCollapse,
} from "mdb-vue-ui-kit";

export default {
	components: {
		MDBNavbarToggler,
		MDBNavbarNav,
		MDBNavbarItem,
		MDBNavbar,
		MDBNavbarBrand,
		MDBCollapse,
	},
	data() {
		return {
			menuIsOpen: false,
		};
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters.isAuthentificated;
		},
	},
	methods: {
		toggleMenu(value) {
			this.menuIsOpen = value;
			this.overlayState(value);
		},
		async logout() {
			await this.$store.dispatch("logout");
			this.$router.replace("/auth");
		},
	},
};
</script>

<style lang="scss">
.nav-link {
	color: $primary-color;
}
</style>
