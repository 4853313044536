import firebaseApp from "../../../firebaseInit";

export default {
	async downloadData(context) {
		const userId = context.rootGetters.userId;

		const isCash = (id) => {
			const regex = new RegExp('_cash', "gi");

			return !!id.match(regex)?.length;
		}


		await firebaseApp.firestore()
			.collection("users")
			.doc(userId)
			.get()
			.then((doc) => {
				if (doc.exists && Object.keys(doc.data()).length !== 0) { //check if there is any data for this period
					const responseData = doc.data();

					const data = [];

					for (const key in responseData) {
						console.log(key);
						const item = {
							amount: responseData[key].amount,
							balance: responseData[key].balance / 100,
							cashbackAmount: responseData[key].cashbackAmount / 100,
							commissionRate: responseData[key].commissionRate / 100,
							currencyCode: responseData[key].description,
							hold: responseData[key].hold,
							id: responseData[key].id,
							mcc: isCash(responseData[key].id) ? -1 : responseData[key].mcc,
							operationAmount: responseData[key].operationAmount / 100,
							receiptId: responseData[key].receiptId,
							time: (new Date(responseData[key].time * 1000)).toLocaleString('uk-UA', { timeZone: 'Europe/Kiev' }),
						};
						isCash(responseData[key].id);

						data.unshift(item);
					}
					context.commit('databaseHasError', false);
					context.commit('databaseIsEmpty', false);
					context.commit('fillLocalData', data);
				} else {
					// doc.data() will be undefined in this case
					console.log("No such document or there is any notes for this period!");
					context.commit('databaseIsEmpty', true);
				}
			}).catch((error) => {
				console.log("Error getting document:", error);
				context.commit('databaseHasError', true);
			});
	},
}
